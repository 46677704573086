import Header from "../../components/Header"
import Footer from "../../components/Footer"

function Home () {

    return (
        <>
            <Header />
            <Footer/>
        </>
    )
}

export default Home