import PoliticaPrivacidade from "../../components/PoliticaPrivacidade"
import Footer from "../../components/Footer"

function Home () {

    return (
        <>
            <PoliticaPrivacidade/>
            <Footer/>
        </>
    )
}

export default Home