import Routes from "./routes/Routes";
import "./styles/global.css"

function App() {

  return (
    <div className="App">
        <Routes />
    </div>
  )
}

export default App;
